/* =OVERWRITE bootstrap values */
a {
    color: #5ea2cf;
}

a>label {
    color: #ACB8C1;
}
a>label:hover,
a>label:focus {
  color: #fff;
}
p {
    margin: 0 0 20px;
    color: #999999;
}

.i-margin {
    margin-right: 5px;
    margin-left: 15px;
    align-content: center;
    align-items: center;
}
.p-color {
    color: #333;
}

.border-shadow {
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .08);
    padding: 0px 30px;
    border: 1px solid rgb(229, 229, 229);
}

h5,
h6 {
    margin-top: 5px;
    margin-top: 5px;
    font-weight: bold;
}

.footer-p {
    background-color: #434547;
    width: 100%;
    padding: 30px;
}
/*
.header-p {
    background-color: #434547;
    height: 18;
    display: 'flex';
    align-items: 'center';
    flex-direction: 'row';
    width: '100%';
    margin-right: 'auto';
    margin-left: 'auto';
    padding-left: '15px';
    padding-right: '15px';
    padding: 8px 15px 7px 15px;
}

.header-p2 {
    color: #ACB8C1;
    font-weight: 'bolder';
    width: '50%';
    text-align: 'right';
    white-space: 'nowrap';
    text-overflow: 'ellipsis';
    overflow: 'hidden';
    padding-right: 10;
}

.header-p3 {
    color: #ACB8C1;
    font-weight: 'bolder';
    width: '45%';
    text-align: 'left';
    white-space: 'nowrap';
    text-overflow: 'ellipsis';
    overflow: 'hidden';
}*/
/*= Accordion */
h3.accordion-header {
    font-size: 1.2em;
    line-height: 2em;
    margin-bottom: 0;
    margin-top: 0;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+18,ffffff+100 */
    background: #fff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #eeeeee 0%, #eeeeee 18%, #ffffff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #eeeeee 0%, #eeeeee 18%, #ffffff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #eeeeee 0%, #eeeeee 18%, #ffffff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */


}

h3.accordion-header.active a {
    color: #434547;
}

.accordion-header,
.accordion-step-container {
    border-left: 1px #eee solid;
    border-right: 1px #eee solid;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.accordion-step-container {
    padding: 15px;
    border-bottom: 1px #eee solid;
}

.noPadding15 {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 2px;
}

h3.accordion-header small {
    font-size: 1em;
    line-height: 2.5em;
}

.accordion-step-container {
    display: none;
}

.list-style-none {
    list-style-type: none;
}

.btn-primary {
    background-color: #5ea2cf!important;
    border-color: #fff!important;
    min-width: 120px !important;
    font-weight: 700;
}

.btn-primary:hover {
    background-color: #354167!important;
    border-color: #fff;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:hover,
.btn-primary.active:hover,
.open>.dropdown-toggle.btn-primary {
    background-color: #8A8D8F!important;
    border-color: #5ea2cf;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus,
header.navbar>li>a,
header.navbar>li>a:hover,
header.navbar>li.active>a:focus,
.navbar-inverse .navbar-nav>li>a {
    background-color: #5ea2cf;
    color: #fff;
}

header .navbar-nav>li>a {
    font-size: 14px;
    line-height: 20px;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
    background-color: #5ea2cf;
}


.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    background-color: #5ea2cf;
    color: #fff;
}

body {
   /* -webkit-font-smoothing: antialiased;*/
    /*font-family: 微软雅黑, 华文细黑, "Microsoft YaHei New", "Microsoft Yahei", "Helvetica Neue", Helvetica, Arial, SimSun, STXihei, 宋体, sans-serif;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
    font-family: Roboto, sans-serif;*/
  /*  font-family: "Open Sans", Sans-serif!important;
    font-size: 14px!important;
    padding: 95px 0 0!important;
    margin: 0;
    line-height: 1.5;
    font-weight: 400;
    text-align: left;
    text-transform: none;
    font-style: normal;
    color:#23282D!important;
    letter-spacing: 0px;
    min-height: 950px;*/
    -webkit-font-smoothing: antialiased;
    font-family: Avenir, sans-serif, ZCool, 微软雅黑, 华文细黑, "Microsoft YaHei New", "Microsoft Yahei", "Helvetica Neue", Helvetica, Arial, SimSun, STXihei, 宋体, sans-serif;
    font-size: 16px;
    padding: 100px 0 0;
    margin: 0;
}
.wraper{
    margin: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}
#mainHeaderWebPageWrapper>nav.navbar {
    background-color: #fff;
    margin: 0 0 40px;
    min-height: 80px;
    color: #5ea2cf;
}

hr {
    margin: 10px 0;
}

body>main {
    min-height: 750px;
}

body>footer {
    margin: 30px 0 0;
    font-size: 18px;
    padding: 20px 0px 0px;
    min-height: 100px;
    text-align: center;
    font-size: 14px;
}

main {
    padding: 0 20px;
}

/* = */

/* the overlayed element */
.simple_overlay {

    /* must be initially hidden */
    display: none;

    /* place overlay on top of other elements */
    z-index: 10000;

    /* styling */
    background-color: #fff;

    width: 1024px;
    min-height: 200px;
    /*border:1px solid #666;*/

    /* CSS3 styling for latest browsers */
    -moz-box-shadow: 0 0 90px 5px #000;
    -webkit-box-shadow: 0 0 90px #000;
}

/* close button positioned on upper right corner */
.simple_overlay .close {
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
    font-size: 50px;
}

.simple_overlay .print {
    position: absolute;
    right: 80px;
    top: 0;
    cursor: pointer;
    font-size: 50px;
}


/* =custom columns */
.col-md-a {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

/* =Wizard */
.wizard-panel {}

.wizard-panel .list-group-item-text {
    min-height: 45px;
}

/* =Content-Panel */
.show-when-second-column,
.column-secondary-contact {
    display: none;
}

.has-second-column.show-second-column {
    padding: 3px;
    background: #ffffff;
}

.column-first-contact .has-second-column.show-second-column {
    background: transparent;
}

.has-second-column.show-second-column .show-when-second-column {
    display: block;
}

.has-second-column.show-second-column .column-first-contact,
.has-second-column.show-second-column .column-secondary-contact {
    background-color: #fff;
}

.content-panel>.column-first-contact .has-second-column.show-second-column .column-first-contact,
.content-panel>.column-first-contact .has-second-column.show-second-column .column-secondary-contact {
    background-color: transparent;
}


.has-second-column.show-second-column .column-secondary-contact {
    display: block;
}

.has-second-column.show-second-column .column-first-contact .col-md-1,
.has-second-column.show-second-column .column-first-contact .col-md-2,
.has-second-column.show-second-column .column-first-contact .col-md-3,
.has-second-column.show-second-column .column-first-contact .col-md-4,
.has-second-column.show-second-column .column-first-contact .col-md-5,
.has-second-column.show-second-column .column-first-contact .col-md-6,
.has-second-column.show-second-column .column-first-contact .col-md-7,
.has-second-column.show-second-column .column-first-contact .col-md-8,
.has-second-column.show-second-column .column-first-contact .col-md-9,
.has-second-column.show-second-column .column-first-contact .col-md-10,
.has-second-column.show-second-column .column-first-contact .col-md-11,
.has-second-column.show-second-column .column-first-contact .col-md-12,
.has-second-column.show-second-column .column-secondary-contact .col-md-1,
.has-second-column.show-second-column .column-secondary-contact .col-md-2,
.has-second-column.show-second-column .column-secondary-contact .col-md-3,
.has-second-column.show-second-column .column-secondary-contact .col-md-4,
.has-second-column.show-second-column .column-secondary-contact .col-md-5,
.has-second-column.show-second-column .column-secondary-contact .col-md-6,
.has-second-column.show-second-column .column-secondary-contact .col-md-7,
.has-second-column.show-second-column .column-secondary-contact .col-md-8,
.has-second-column.show-second-column .column-secondary-contact .col-md-9,
.has-second-column.show-second-column .column-secondary-contact .col-md-10,
.has-second-column.show-second-column .column-secondary-contact .col-md-11,
.has-second-column.show-second-column .column-secondary-contact .col-md-12 {
    width: 100%;
}

.has-second-column.show-second-column .guidelines {
    display: none;
}

/* =Background */
.background-style-1 {
    background-color: #f7f7f7;
    margin: 20px 0;
    padding: 20px 10px;
    border: 1px solid #b1b1b1;
}

.background-style-2 {
    background-color: #f7f7f7;
    margin-bottom: 20px;
    padding: 5px 20px 15px;
    border: 1px solid #b1b1b1;
}

.background-style-3 {
    background-color: #f7f7f7;
    margin: 0;
    padding: 10px 20px;
    border: 1px solid #b1b1b1;
}

/* =Border */
.border-style-1 {
    border: 1px solid #fff;
}

/* =Font Size */
.fnt-size-1 {
    font-size: 1em;
}

.fnt-size-08 {
    font-size: 0.8em;
}

/* =Form */
form label {
    margin-right: 5px;
}

form label:last-child {
    margin-right: 0;
}

form label>input[type="checkbox"] {
    margin-right: 5px;
    margin-bottom: 5px;
}

form label>input[type="radio"] {
    margin-left: 0;
}

form label.error {
    font-size: 16px;
    color: red;
    font-weight: normal;
}

.form-group label {
    font-weight: normal;
}

.form-group {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 5px;
}

.form-control {
    padding: 4px 5px;
    /* height: 30px; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

label.small {
    font-size: 16px;
    margin-top: 5px 0;
    font-weight: normal;
}

form .select2-selection__placeholder,
form .select2-selection__rendered {
    font-size: 0.90em;
}

.input_placeholder {
    color: #999999;
    opacity: 1;
}

input[type="radio"],
input[type="checkbox"] {
    margin-left: 5px;
}

.form-inline .style-text-form {
    overflow: hidden;
    background-color: transparent;
}

.form-inline .style-text-form>input {
    background-color: transparent;
    border: none !important;
    border-bottom: 1px dotted #b2b2b2 !important;
    vertical-align: text-bottom;
    width: 100%;
    border-radius: 0;
}

/* =Text */
.txt-a-r {
    text-align: right;
}

.txt-a-c {
    text-align: center;
}

.txt-underline {
    text-decoration: underline;
}

.txt-note {
    font-weight: bold;
    color: #8d1d25;
    text-decoration: underline;
}

/* =Padding */
.no-padding {
    padding: 0;
}

.pd-2p {
    padding: 2px
}

.pd-5p {
    padding: 5px
}

.pd-10p {
    padding: 10px
}

.pd-20p {
    padding: 20px
}

.pd-l-10p {
    padding-left: 10px
}

.pd-l-20p {
    padding-left: 20px
}

.pd-r-10p {
    padding-right: 10px
}

.pd-r-20p {
    padding-right: 20px
}

/* =Margin */
.no-mrg {
    margin: 0
}

.mrg-t-10p {
    margin-top: 10px
}

.mrg-t-20p {
    margin-top: 20px
}

.mrg-t-30p {
    margin-top: 30px
}

.mrg-t-40p {
    margin-top: 40px
}

.mrg-t-50p {
    margin-top: 50px
}

.mrg-l-5p {
    margin-left: 10px
}

.mrg-l-10p {
    margin-left: 10px
}

.mrg-r-10p {
    margin-right: 10px
}

.mrg-b-10p {
    margin-bottom: 10px
}

.mrg-b-20p {
    margin-bottom: 10px
}

/* =Display */
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

/* =Position */
.relative {
    position: relative;
}

/* =Width */
.max-width-years {
    max-width: 50px;
}

.max-width-text {
    max-width: 400px;
}

.min-width-text {
    min-width: 250px;
}

.min-width-date {
    min-width: 100px;
}

.width-100 {
    width: 100%;
}

/* =Jquery-widget */
.ui-widget {
    font-size: 1.3rem;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    color: rgb(51, 51, 51);
}

/* =overflow*/
.over-hidden {
    overflow: hidden;
}

/* =DatePicker */
.form-group .ui-datepicker-trigger {
    position: absolute;
    top: 0px;
    width: 29px;
    height: 29px;
    right: 14px;
}

.currency,
.integer {
    text-align: right;
}


/* = Display*/
ds-none {
    display: none;
}

/* =Table */
@media only screen and (min-width: 768px) {
    table {
        table-layout: fixed;
    }

    .td-width-210p {
        width: 210px;
    }

    .min-height-wizard-desktop {
        min-height: 80px;
    }

    .has-second-column.show-second-column {
        /*background-color:#dddcdc;*/
    }

    .has-second-column.show-second-column .column-first-contact,
    .has-second-column.show-second-column .column-secondary-contact {
        width: 49%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;

        /*
    width:50%;
    float:left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    */
    }

    .has-second-column.show-second-column .column-first-contact {
        border-right: 1px dotted rgb(51, 51, 51);
        padding-right: 5px;
        margin-right: -1px;
    }

    .has-second-column.show-second-column .column-secondary-contact {
        clear: right;
        border-left: 1px dotted rgb(51, 51, 51);
        padding-left: 5px;
        margin-left: -4px;
    }

    .column-first-contact .has-second-column.show-second-column .column-secondary-contact {
        border-left: none;
    }

    .has-second-column.show-second-column .column-first-contact .col-middle,
    .has-second-column.show-second-column .column-secondary-contact .col-middle {
        width: 50%;
    }
}


/* 
  =Styles from WebSite
  ------------------------------------------------------------------------------------------------------------------------------    
*/
a:hover {
    /* text-decoration: none; */
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.undis {
    display: none;
}

.dis {
    display: block;
}

.mt2 {
    margin-top: 2%;
}

.mt5 {
    margin-top: 5%;
}

.mt20 {
    margin-top: 20px;
}

.mtb2 {
    margin-top: 2%;
    margin-bottom: 2%;
}

.mtb3 {
    margin-top: 3%;
    margin-bottom: 3%;
}

.mtb5 {
    margin-top: 5%;
    margin-bottom: 5%;
}

.mc {
    margin: 0 auto;
}

.tc {
    text-align: center;
}

.red {
    color: #df1233;
}

.white {
    color: #ffffff;
}

.gray {
    color: #414141;
}

.light_gray {
    color: #818181;
}

.pointer {
    cursor: hand;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html,
body {
    height: 100%;
}

body {
    margin: 0 auto;
}

.img-home-portfolio,
.img-customer,
.portfolio-item {
    margin-bottom: 30px;
}

.tab-pane {
    margin-top: 15px;
}

/* Page Sections */
.section,
.section-colored {
    padding: 50px 0;
}

.section-colored {
    background-color: #e1e1e1;
}

.sidebar {
    margin-top: 40px;
}

.sidebar ul {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #cccccc;
}

.carousel {
    height: 50%;
}

.item,
.active,
.carousel-inner {
    height: 100%;
}

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

/* Footer Styles */
/*html,
body {
   margin:0;
   padding:0;
   height:100%;
}
#container {
   position:relative;
}
#header {
   padding:10px;
}
#body {
   padding:10px;
   padding-bottom:60px;   /* Height of the footer */
/*}
#footer {
   position:absolute;
   bottom:0;
   width:100%;
   height:60px;   /* Height of the footer */
/*}*/

footer {
    margin: 15px 0 0;
    font-size: 18px;
    padding: 20px 0px 0px;
    min-height: 100px !important;
    text-align: center;
    font-size: 14px;
}

footer ul {
    margin: 20px 0px 20px 0px;
}

footer li {
    list-style: none;
}

footer .icons {
    float: left;
}

/*footer .facebook{background:url(../img/icons.png) no-repeat 0px 0px;width:64px;height:64px;margin:5px;}
footer .linkedin{background:url(../img/icons.png) no-repeat -156px 0px;width:64px;height:64px;margin:5px;}
footer .twitter{background:url(../img/icons.png) no-repeat -78px 0px;width:64px;height:64px;margin:5px;}
footer .youtube{background:url(../img/icons.png)}
footer .googleplus{background:url(../img/icons.png)}
 */
/* Navbar Styles */
.navbar {
    background-color: #434547;
}

.navbar-right {
    margin: 10px;
}

.navbar-center {
    margin: 25px;
}

.navbar-inverse {
    /* background: #fff url("../../images/top-background.png") repeat-x fixed 0 -22px; */
    min-height: 30px;
}

.navbar .logo {
    margin-top: 10px;
    height: 50px;
    display: block;
}

.navbar-nav a {
    font-size: 14px;
    color: white;
}

.navbar-inverse .navbar-nav>li>a {
    color: white;
}

.navbar-nav>li>a {
    padding: 8px;
    margin-top: 15px;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
    background: #FFFF;
}

.dropdown-menu {
    color: white;
}

.dropdown-menu>li>a {
    color: black;
}

/*
.registration { background:url(../img/icon1.png); width: 200px;height:200px;margin:0 auto;}
.account {background:url(../img/icon2.png); width: 200px;height:200px;margin:0 auto;}
.plateform { background:url(../img/icon3.png); width: 200px;height:200px;margin:0 auto;}*/
.indexbox {
    border: 1px solid white;
    box-shadow: 0 3px 3px #e8e8e8;
    background: #fbf9fa;
    text-align: center;
    min-height: 400px;
    margin: 2px;
}

/* 
  =Sidebar - Intranet
  ------------------------------------------------------------------------------------------------------------------------------    
*/

.sidebar {
    margin-top: 20px;
}

.sidebar-left ul {
    border: none;
    border-right: 1px solid #ccc;
}

.sidebar ul {
    list-style-type: none;
}

.sidebar ul li {
    margin: 5px 0 20px;
}

.sidebar ul li a {
    font-size: 16px
}

@media print {
    a[href]:after {
        content: none !important;
    }

    abbr[title]:after {
        content: none !important;
    }
}

/* =custom columns */
@media (min-width:992px) {
    .col-md-a {
        float: left
    }

    .col-md-a {
        width: 19%;
    }
}

.react-tel-input .form-control {
    border-radius: 4px !important;
    /* height: 30px !important; */
    width: 100% !important;
    font-size: 16px !important;
}

.react-tel-input {
    font-family: Avenir, sans-serif !important;
    font-size: 13px !important;
}

.like-link {
    cursor: pointer;
}

.MuiTypography-body1 {
    font-size: 16px !important;
}

.MuiTypography-h6 {
    font-size: 18px !important;
}

.fab-fixed {
    position: fixed;
    right: calc(100%/10);
    bottom: calc(100%/10);
    z-index: 1000;
}
.dd-wrapper{
width: 100%!important;
}

.extended-field {
    max-height: 77.4px;
}

.extended-multiselect {
    max-height: 34px !important;
}

.rmsc .dropdown-heading {
    height: 33px !important;
}

.extended-datepicker {
    background: #fff !important;
}

.field-datepicker {
    background: #fff !important;
}